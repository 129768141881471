import PersonIcon from "@mui/icons-material/Person";

import List from "./List";
import Edit from "./Edit";

export default {
  list: List,
  edit: Edit,
  icon: PersonIcon,
};
