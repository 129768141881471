import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  TopToolbar,
  ExportButton,
  EditButton,
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

const CustomerList = () => {
  return (
    <List actions={<ListActions />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="customerId" />
        <TextField source="name" />
        <TextField source="username" />
        <TextField source="email" />
        <TextField source="provider" />
        <BooleanField source="emailVerified" />
        <BooleanField source="isActive" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CustomerList;
