import { AUTH_LOGIN } from "react-admin";

const baseURL = process.env.REACT_APP_API_BASE_URL || "";
const auth = async (type: any, params: any) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${baseURL}/auth`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const { token } = await response.json();
    console.log("token", token);
    localStorage.setItem("token", token);
  }
  return Promise.resolve();
};

export default auth;
