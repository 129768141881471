import Welcome from "./Welcome";
const Dashboard = () => {
  return (
    <>
      <Welcome />
    </>
  );
};

export default Dashboard;
