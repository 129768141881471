import { Box, Typography } from "@mui/material";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
} from "react-admin";

const EditCustomer = () => (
  <Edit>
    <Box p={4}>
      <Typography ml={2} variant="h5">
        Edit Customer
      </Typography>
      <SimpleForm>
        <TextInput source="id" label="id" disabled />
        <TextInput source="customerId" label="Customer Id" />
        <TextInput source="name" validate={required()} />
        <TextInput source="username" validate={required()} />
        <TextInput source="email" validate={required()} />
        <TextInput source="provider" validate={required()} />
        <BooleanInput label="email verified?" source="emailVerified" />
        <BooleanInput label="is active?" source="isActive" />
      </SimpleForm>
    </Box>
  </Edit>
);

export default EditCustomer;
